import React from 'react';
import './business-card.scss';
import {applyEasingTiming, convertToEntities, isTouchDevice, setDocumentTitle} from "../../util/util";

import {browserIs} from "../../util/browserIs";
import Socmed from "../socmed/socmed";
import VcardDownload from "../vcard-download/vcardDownload";

export class BusinessCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            enableShiftView: false,
        };
        this.refCard = React.createRef();
        this.refWrapper = React.createRef();
        this.refBackground = React.createRef();
        this.refMouseCatcher = React.createRef();

        this.refName = React.createRef();
        this.refTitle = React.createRef();
        this.refPhone = React.createRef();
        this.refEmail = React.createRef();
    }

    componentDidMount() {

        setDocumentTitle(null);

        if (isTouchDevice()) {
            this.refMouseCatcher.current.addEventListener('touchmove', this.onMoveInputDevice.bind(this));
            this.refMouseCatcher.current.addEventListener('touchend', this.onInputDeviceLeave.bind(this));
        } else {
            this.refMouseCatcher.current.addEventListener('mousemove', this.onMoveInputDevice.bind(this));
            this.refMouseCatcher.current.addEventListener('mouseleave', this.onInputDeviceLeave.bind(this));
            this.refMouseCatcher.current.addEventListener('mouseenter', this.onInputDeviceEnter.bind(this));
        }

        if (this.state.enableShiftView) {
            this.enableFluidTransitions();
        }
    }

    render() {
        return (
            <div className={"page"}>
                <div className={'mouse-catcher run-intro'} ref={this.refMouseCatcher}>
                    {/*<Headtracker enabled={false} onTracking={shiftByHeadTracker.bind(this)} onTrackingLost={onInputDeviceLeave.bind(this)}/>*/}
                    {!browserIs.safari ? <div className={'background'} ref={this.refBackground}/> : ''}
                    <div className={'wrapper'}
                         onAnimationEnd={this.onIntroEnd.bind(this)}
                         ref={this.refWrapper}>
                        {browserIs.safari ? <div className={'background background--safari'}/> : ''}
                        <div className={'business-card'} ref={this.refCard}>
                            <div className={'business-card__backdrop-filter'}/>
                            <div className={'business-card__name'} ref={this.refName}>
                                Leon Dietsch
                            </div>
                            <div className={'business-card__title'} ref={this.refTitle}>
                                Web-Entwickler für <br/> TYPO3 CMS und Angular
                            </div>
                            <div className={'business-card__contact'}>
                                <a href="tel:+4917621015456" className={'business-card__phone'} ref={this.refPhone}>
                                    +49 176 210 154 56
                                </a>
                                <a href={'mailto:' + convertToEntities('hallo@leondietsch.de')}
                                   className={'business-card__mail'}
                                   ref={this.refEmail}>
                                    {convertToEntities('hallo@leondietsch.de')}
                                </a>
                            </div>
                            <Socmed/>
                            <VcardDownload/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    onIntroEnd(event) {
        if (event.animationName === 'cardRotateIn') {
            this.enableFluidTransitions();
            this.setState({enableShiftView: true});
            this.refMouseCatcher.current.classList.remove('run-intro');
        }
    }

    // shiftByHeadTracker(offsetX, offsetY) {
    //     const rotateMax = 30;
    //     const rotateX = rotateMax * offsetY / 100;
    //     const rotateY = rotateMax * offsetX / 100;
    //
    //     this.updateShiftAnimation(rotateX, rotateY);
    // }

    enableFluidTransitions() {
        const transition = browserIs.edge || browserIs.ie ? 'none' : 'transform 75ms linear';
        applyEasingTiming(
            transition,
            'none',
            [this.refWrapper, this.refBackground]
        );

        for (let ref of [this.refName, this.refTitle, this.refPhone, this.refEmail]) {
            ref.current.style.transition = 'transform 25ms linear';
        }
    }

    onInputDeviceEnter() {
        if (!this.state.enableShiftView || !this.props.followMouse || !this.refWrapper || !this.refBackground) {
            return;
        }

        this.enableFluidTransitions();
    }

    onMoveInputDevice(event) {

        if (!this.state.enableShiftView || !this.props.followMouse) {
            return;
        }
        event.preventDefault();
        event.stopPropagation();

        let clientX, clientY;

        if (event.touches && event.touches.length > 0) {
            clientX = event.touches[0].clientX;
            clientY = event.touches[0].clientY;
        } else {
            clientX = event.clientX;
            clientY = event.clientY;
        }

        // console.log(clientX, clientY);

        this.shiftView(clientX, clientY);
        !browserIs.edge && !browserIs.ie && (this.moveCardElements(clientX, clientY));
    }

    onInputDeviceLeave(event) {

        if (!this.state.enableShiftView || !this.props.followMouse) {
            return;
        }

        event.stopPropagation();

        const transition = 'transform 750ms ease-out';

        let refs = [this.refWrapper];
        !browserIs.safari && (refs.push(this.refBackground));

        requestAnimationFrame(() => {
            applyEasingTiming(transition, `rotateX(0deg) rotateY(0deg)`, refs);
            applyEasingTiming(transition, `translateZ(1px)`, [
                this.refWrapper,
                this.refName,
                this.refTitle,
                this.refPhone,
                this.refEmail
            ]);
        });
    }

    moveCardElements(clientX, clientY) {
        const center = {x: window.innerWidth / 2, y: window.innerHeight / 2};

        const shiftX = 100 / center.x * (clientX - center.x);
        const shiftY = 100 / center.y * (clientY - center.y);

        const threshold = (func) => {
            return (max) => {
                const val = func(max);
                return val > 0 ? val : 0;
            }
        };
        const upperLeft = threshold((max) => max * (-shiftY + -shiftX) / 100);
        const lowerRight = threshold((max) => max * (shiftY + shiftX) / 100);

        this.refName.current.style.transform = `translateZ(${upperLeft(50)}px)`;
        this.refTitle.current.style.transform = `translateZ(${upperLeft(40)}px)`;

        this.refPhone.current.style.transform = `translateZ(${lowerRight(40)}px)`;
        this.refEmail.current.style.transform = `translateZ(${lowerRight(50)}px)`;
    }

    shiftView(clientX, clientY) {
        const rotateMax = 20;

        let shiftX, shiftY, rotateX, rotateY;

        const center = {x: window.innerWidth / 2, y: window.innerHeight / 2};

        shiftX = 100 / center.x * (clientX - center.x);
        shiftY = 100 / center.y * (clientY - center.y);

        rotateX = rotateMax * -shiftY / 100;
        rotateY = rotateMax * shiftX / 100;

        this.updateShiftAnimation(rotateX, rotateY);
    }

    updateShiftAnimation(rotateX, rotateY) {

        const refs = [this.refWrapper];
        !browserIs.safari && (refs.push(this.refBackground));

        requestAnimationFrame(() => {
            applyEasingTiming(
                false,
                `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`,
                refs
            );
        });
    }

}